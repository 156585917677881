import * as THREE from 'three';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils';
import { throttle } from 'lodash-es';

// Define the scene constants
const CANVAS = document.querySelector('#canvas');
const BREAKPOINT = 800;

const SCENE_ANTIALIAS = true;
const SCENE_ALPHA = true;

const CAMERA_FOV = 20;
const CAMERA_NEAR = 100;
const CAMERA_FAR = 500;
const CAMERA_X_SMALL_SCREEN = 0;
const CAMERA_X_LARGE_SCREEN = -50;
const CAMERA_Y_SMALL_SCREEN = 50;
const CAMERA_Y_LARGE_SCREEN = 0;
const CAMERA_Z = 250;

const BOX_GRID_GAP = 2;
const BOX_MAX_DEPTH = 15;
const BOX_SIZE = 2;
const BOX_ROW_COUNT = 16;

const PLANET_RADIUS = 5;
const PLANET_SEGMENTS = 16;
const PLANET_MOVEMENT_RADIUS = 22;

const CRATER_WIDTH = 40;

const SQUARE_COUNT = 20;
const SQUARE_DISTANCE = 8;
const SQUARE_COLOR = 0x444444;

const PINK_COLOR = 0xf73ea4;
const PURPLE_COLOR = 0x8e38ff;
const BLUE_COLOR = 0x445dfc;

// Render the scene.
const renderScene = () => {
	// Define the renderer, in this case WebGL.
	const renderer = new THREE.WebGLRenderer({
		canvas: CANVAS,
		antialias: SCENE_ANTIALIAS,
		alpha: SCENE_ALPHA,
	});

	// // Set up and position the camera.
	const camera = new THREE.PerspectiveCamera(
		CAMERA_FOV,
		CANVAS.width / CANVAS.height,
		CAMERA_NEAR,
		CAMERA_FAR,
	);

	// Position the camera.
	camera.position.set(
		CANVAS.width > BREAKPOINT ? CAMERA_X_LARGE_SCREEN : CAMERA_X_SMALL_SCREEN,
		CANVAS.width > BREAKPOINT ? CAMERA_Y_LARGE_SCREEN : CAMERA_Y_SMALL_SCREEN,
		CAMERA_Z,
	);

	// Set up the scene.
	const scene = new THREE.Scene();

	const planetGeometry = new THREE.SphereGeometry(PLANET_RADIUS, PLANET_SEGMENTS, PLANET_SEGMENTS);
	const planetMaterial = new THREE.MeshLambertMaterial();

	const planetMesh = new THREE.Mesh(planetGeometry, planetMaterial);

	planetMesh.position.set(0, PLANET_MOVEMENT_RADIUS, 15);

	const startPositionX = -(
		((BOX_ROW_COUNT / 2) * BOX_SIZE)
		+ ((BOX_ROW_COUNT / 2) * BOX_GRID_GAP)
		- (BOX_GRID_GAP / 2)
	);

	const startPositionY = -(
		((BOX_ROW_COUNT / 2) * BOX_SIZE)
		+ ((BOX_ROW_COUNT / 2) * BOX_GRID_GAP)
		- (BOX_GRID_GAP / 2)
	);

	const generateGridGeometry = (spherePosition) => {
		const boxGeometries = [];

		for (let column = 0; column < BOX_ROW_COUNT; column += 1) {
			for (let row = 0; row < BOX_ROW_COUNT; row += 1) {
				const boxVector = new THREE.Vector3(0, 0, 0);

				boxVector.x = startPositionX + (BOX_SIZE * column) + (BOX_GRID_GAP * column);
				boxVector.y = startPositionY + (BOX_SIZE * row) + (BOX_GRID_GAP * row);

				const easingFunction = (x) => (x < 0.5 ? 2 * x * x : 1 - ((-2 * x + 2) ** 2) / 2);

				const depthShouldVary = boxVector.distanceTo(spherePosition) < CRATER_WIDTH;

				const boxDepth = depthShouldVary ? (
					easingFunction(boxVector.distanceTo(spherePosition) / CRATER_WIDTH)
				) * BOX_MAX_DEPTH : BOX_MAX_DEPTH;

				const boxGeometry = new THREE.BoxGeometry(
					BOX_SIZE,
					BOX_SIZE,
					boxDepth,
				);

				boxVector.z = boxGeometry.parameters.depth / 2;

				boxGeometry.translate(boxVector.x, boxVector.y, boxVector.z);

				boxGeometries.push(boxGeometry);
			}
		}

		return BufferGeometryUtils.mergeBufferGeometries(
			boxGeometries,
		);
	};

	const gridGeometry = generateGridGeometry(planetMesh.position);
	const gridMaterial = new THREE.MeshLambertMaterial();

	const gridMesh = new THREE.Mesh(gridGeometry, gridMaterial);

	const planetLight = new THREE.PointLight(BLUE_COLOR, 3, 100);

	planetLight.position.set(0, 0, 50);

	const group = new THREE.Group();

	group.add(planetMesh);
	group.add(gridMesh);
	group.add(planetLight);

	group.rotateX(-Math.PI / 4);
	group.rotateZ(Math.PI / 4);

	for (let square = 0; square < SQUARE_COUNT; square += 1) {
		const squareSize = (BOX_ROW_COUNT * BOX_SIZE)
			+ ((BOX_ROW_COUNT - 1) * BOX_GRID_GAP)
			+ (square * (2 * SQUARE_DISTANCE)) + (2 * SQUARE_DISTANCE);
		const squareGeometry = new THREE.PlaneGeometry(squareSize, squareSize);

		squareGeometry.translate(0, 0, 0);

		const squareMaterial = new THREE.MeshBasicMaterial({
			color: SQUARE_COLOR,
			wireframe: true,
			transparent: true,
			opacity: 1 - (square / SQUARE_COUNT),
		});

		const squareMesh = new THREE.Mesh(squareGeometry, squareMaterial);

		group.add(squareMesh);
	}

	scene.add(group);

	const pinkLight = new THREE.DirectionalLight(PINK_COLOR, 1);

	pinkLight.position.set(100, -100, 0);

	scene.add(pinkLight);

	const purpleLight = new THREE.DirectionalLight(PURPLE_COLOR, 1);

	purpleLight.position.set(-100, -100, 0);

	scene.add(purpleLight);

	// Animate the scene using the browser's native requestAnimationFrame method.
	const animate = (time) => {
		time *= 0.001;

		const planetPositionX = Math.cos(time) * PLANET_MOVEMENT_RADIUS;
		const planetPositionY = Math.sin(time) * PLANET_MOVEMENT_RADIUS;

		planetMesh.position.y = planetPositionX;
		planetMesh.position.x = planetPositionY;

		gridMesh.geometry = generateGridGeometry(planetMesh.position);

		// Re-render the scene and trigger another animation frame.
		renderer.render(scene, camera);

		requestAnimationFrame(animate);
	};

	// Trigger the first animation frame.
	requestAnimationFrame(animate);

	window.addEventListener(
		'resize',
		throttle(
			() => {
				const width = window.innerWidth;
				const height = window.innerHeight;
				camera.aspect = width / height;
				camera.updateProjectionMatrix();
				renderer.setSize(width, height);
				CANVAS.width = width;
				CANVAS.height = height;
			},
			500,
			{ trailing: true },
		),
	);
};

// Establish the canvas size and call the function to render the scene.
CANVAS.width = window.innerWidth;
CANVAS.height = window.innerHeight;

renderScene();
